@font-face {
  font-family: Aileron;
  src: url("Aileron-Bold.8ad0a026.woff2");
  font-weight: bold;
}

.nav-current {
  color: #b3d5fc;
}

.a_link {
  color: #fff;
  font-family: Aileron;
  font-size: 20px;
  text-decoration: none;
  display: flex;
}

.bg-gif {
  object-fit: fill;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.cta {
  color: #fff;
  background: #050221;
  padding: 10px 25px;
  font-family: Aileron;
  font-size: 20px;
  text-decoration: none;
  transition: all 1s;
  display: flex;
  transform: skewX(-15deg);
  box-shadow: 6px 6px #fffdfd4d;
}

.svg_home {
  margin-top: 1px;
  margin-left: -10px;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: all .5s;
  box-shadow: 10px 10px #b3d5fc;
}

.cta span:nth-child(2) {
  margin-right: 0;
  transition: all .5s;
}

.cta:hover span:nth-child(2) {
  margin-right: 45px;
  transition: all .5s;
}

.cta span {
  transform: skewX(15deg);
}

.cta span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: 12%;
}

.cta path.one {
  transition: all .4s;
  transform: translateX(-60%);
}

.cta path.two {
  transition: all .5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: 1s .2s infinite color_anim;
}

.cta:hover path.one {
  animation: 1s .6s infinite color_anim;
  transform: translateX(0%);
}

.cta:hover path.two {
  animation: 1s .4s infinite color_anim;
  transform: translateX(0%);
}

@keyframes color_anim {
  0% {
    fill: #fff;
  }

  50% {
    fill: #b3d5fc;
  }

  100% {
    fill: #fff;
  }
}

@keyframes scroll-indicator-animation {
  0% {
    fill: #fff;
  }

  100% {
    fill: #b3d5fc;
  }
}

.scroll-indicator {
  & svg {
    animation: .8s infinite scroll-indicator-animation;
  }

  & svg:nth-child(2) {
    animation-delay: .4s;
  }

  & svg:nth-child(3) {
    animation-delay: .6s;
  }
}

.hero {
  height: 100dvh;
}

@media (width <= 1200px) {
  .hero {
    height: calc(100dvh - 70px);
  }
}

.hero > section > header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-header {
  display: none;
}

.mobile-header .logo {
  height: 1.625rem;
}

@media (width <= 1200px) {
  .hero > section > header {
    display: none;
  }

  .mobile-header {
    color: #fff;
    z-index: 100;
    background: #fff;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.mobile-header > div {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.hero > section {
  align-items: center;
  height: 100%;
  display: flex;
}

.hero > section h1 {
  max-width: 60rem;
  font-size: 6rem;
  line-height: 120%;
}

@media (width <= 1024px) {
  .hero > section h1 {
    font-size: 5rem;
  }
}

@media (width <= 700px) {
  .hero > section h1 {
    font-size: 4rem;
  }
}

@media (width <= 500px) {
  .hero > section h1 {
    font-size: 3rem;
  }
}

.tech-should {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.tech-should h3 {
  margin-bottom: 4.5rem;
  font-size: 5.4rem;
}

.tech-should .colored-texts {
  font-size: 2.5rem;
  line-height: 3rem;
}

@media (width <= 1024px) {
  .tech-should {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .tech-should h3 {
    margin-bottom: 3.5rem;
    font-size: 4.5rem;
  }

  .tech-should .colored-texts {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media (width <= 700px) {
  .tech-should {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .tech-should h3 {
    margin-bottom: 3rem;
    font-size: 3.5rem;
  }

  .tech-should .colored-texts {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
}

@media (width <= 500px) {
  .tech-should {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .tech-should h3 {
    margin-bottom: 2.5rem;
    font-size: 3rem;
  }

  .tech-should .colored-texts {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

@media (width <= 400px) {
  .tech-should h3 {
    text-align: left;
    margin-bottom: 2rem;
    font-size: 2.4rem;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0)scale(1);
  }

  50% {
    transform: translateY(-30px)scale(1.1);
  }
}

.bounce {
  animation: 1.4s ease-in-out infinite bounce;
}

footer h4 {
  margin-bottom: 32px;
  font-size: 1.6rem;
  font-weight: 500;
}

footer div p {
  font-size: 1rem;
}

@media (width <= 1024px) {
  footer h4 {
    margin-bottom: 20px;
    font-size: 1.4rem;
  }

  footer div p {
    font-size: .9rem;
  }
}

@media (width <= 700px) {
  footer h4 {
    margin-bottom: 16px;
    font-size: 1.2rem;
  }

  footer div p {
    font-size: .8rem;
  }
}
/*# sourceMappingURL=index.fb550921.css.map */
